@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter";
    color: #00354E;
    line-height: '30px';
  }

footer{
  font-family: 'Montserrat Variable', sans-serif;
}

select {
  -webkit-appearance: none;
}


.ordered-list {
  list-style-type: decimal;
  padding-left: 20px;
}
.unordered-list {
  list-style-type: disc;
  padding-left: 20px;
}
